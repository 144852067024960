/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply bg-base03 text-base0;
    }
    /* p {
        @apply text-sm;
    } */
    h1 {
      @apply text-2xl text-base2;
    }
    h2 {
      @apply text-xl text-base3;
    }
    h3 {
        @apply text-lg text-base3;
    }
}
  
@layer components {
    .btn {
        @apply bg-base02 hover:bg-base03 hover:border text-base3 font-bold py-2 px-4 rounded;
    }
    .swatch {
        @apply w-[50px] h-[50px] text-white;
    }
    .nav-item {
        @apply h-10
    }
    .ql-editor {
        @apply focus:outline-none border-base1 focus:border-base2 border-[1px] rounded-md
    }
    .ql-bold.ql-active, .ql-italic.ql-active {
        @apply text-base2
    }
    /* $.ql-active {
        @apply text-base2
    } */
}


/* EditorJS styles */
.ce-block--selected .ce-block__content,
.ce-inline-toolbar,
.codex-editor--narrow .ce-toolbox,
.ce-conversion-toolbar,
.ce-conversion-tool,
.ce-settings,
.ce-settings__button,
.ce-toolbar__settings-btn,
.cdx-button,
.ce-popover,
.ce-toolbar__plus:hover {
  background: #073642 !important;
  color: white;
}

.ce-inline-tool,
.ce-conversion-toolbar__label,
.ce-conversion-tool__icon,
.ce-toolbox__button,
.cdx-settings-button,
.ce-toolbar__plus {
  color: white !important;
  background: #073642 !important;
  border-radius:25%;
}

::selection {
  background: #002b36 !important;
}

.cdx-settings-button:hover,
.ce-settings__button:hover,
.ce-toolbox__button--active,
.ce-toolbox__button:hover,
.cdx-button:hover,
.ce-inline-toolbar__dropdown:hover,
.ce-inline-tool:hover,
.ce-popover__item:hover,
.ce-toolbar__settings-btn:hover {
  background-color: #002b36 !important;
  color: inherit;
}

.cdx-notify--error {
  background: #fb5d5d !important;
}

.cdx-notify__cross::after,
.cdx-notify__cross::before {
  background: #586e75;
}

/* 'base03':'#002b36',
'base01':'#586e75',
'base00':'#657b83',
'base0':'#839496',
'base1':'#93a1a1',
'base2':'#eee8d5',
'base3':'#fdf6e3', */